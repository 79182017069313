import { Message } from 'element-ui';
import { Loading } from 'element-ui';

export default {
    /**
     * 公共弹窗方法
     * @param {*} msg 
     * @param {*} type 
     */
    showMsg(msg = '', type = 'success'){
        Message({
            type: type,
            message: msg,
            duration: 1500,
            showClose: true
        });
    },
    
    /**
     * 公共loadding方法
     * @param {*} text 
     * @returns 
     */
    showLoad(text = '加载中'){
        return Loading.service({
            lock: true,
            text: text,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        });
    },
    
    /**
     * 判断列表1中是否包含了列表2中的某一项
     * @param {*} list1 
     * @param {*} list2 
     * @returns 
     */
    includeArray(list1, list2){
        if (list1 === true) return true
        if (typeof list2 !== 'object') return false;

        let status = false;
        list2.forEach(item => {
            if (list1.includes(item)) status = true
        })
        return status
    },

    /**
     * 递归获取当前页面路径
     * @param {*} list 
     * @param {*} path 
     * @param {*} bread 
     * @returns 
     */
    getNavCrumb(list, path, bread = []){
        for(let i in list){
            let breads = JSON.parse(JSON.stringify(bread));
            breads.push(list[i].name);
            if(list[i].route == path){
                return breads;
            }
            else if(list[i].children){
                let bread_children = this.getNavCrumb(list[i].children, path, breads);
                if(bread_children.length) return bread_children;
            }
        }
        return [];
    }
}