import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
	  path: '/ai/',
	  name: 'ai_index',
	  meta: {
		  auth: true,
		  title: '首页'
	  },
	  component: () => import('@/pages/ai/index.vue')
	},
	{
	  path: '/ai/shoot',
	  name: 'ai_shoot',
	  meta: {
		  auth: true,
		  title: '拍照'
	  },
	  component: () => import('@/pages/ai/shoot.vue')
	},
	{
	  path: '/ai/load',
	  name: 'ai_load',
	  meta: {
		  auth: true,
		  title: '加载中'
	  },
	  component: () => import('@/pages/ai/load.vue')
	},
	{
	  path: '/ai/gender',
	  name: 'ai_gender',
	  meta: {
		  auth: true,
		  title: '选择性别'
	  },
	  component: () => import('@/pages/ai/gender.vue')
	},
	{
	  path: '/ai/scene',
	  name: 'ai_scene',
	  meta: {
		  auth: true,
		  title: '选择场景'
	  },
	  component: () => import('@/pages/ai/scene.vue')
	},
	{
	  path: '/ai/settle',
	  name: 'ai_settle',
	  meta: {
		  auth: true,
		  title: '扫码支付'
	  },
	  component: () => import('@/pages/ai/settle.vue')
	},
	{
	  path: '/ai/login',
	  name: 'ai_login',
	  meta: {
		  title: '设置设备码'
	  },
	  component: () => import('@/pages/ai/login.vue')
	},
	{
	  path: '/birthday',
	  name: 'birthday_index',
	  meta: {
		  title: '首页'
	  },
	  component: () => import('@/pages/birthday/index.vue')
	},
	{
	  path: '/birthday/born',
	  name: 'birthday_born',
	  meta: {
		  title: '出生信息'
	  },
	  component: () => import('@/pages/birthday/born.vue')
	},
	{
	  path: '/birthday/scene',
	  name: 'birthday_scene',
	  meta: {
		  title: '选择场景'
	  },
	  component: () => import('@/pages/birthday/scene.vue')
	},
	{
	  path: '/birthday/settle',
	  name: 'birthday_settle',
	  meta: {
		  title: '扫码支付'
	  },
	  component: () => import('@/pages/birthday/settle.vue')
	},
	{
	  path: '/birthday/login',
	  name: 'birthday_login',
	  meta: {
		  title: '设置设备码'
	  },
	  component: () => import('@/pages/birthday/login.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})



// /**
//  * 路由拦截
//  * 权限验证
//  */
// router.beforeEach(async (to, from, next) => {
//     if(to.matched.some(record => record.meta.auth)){
// 		// 获取machine_code
// 		let machine_code = store.state.aiMachineCode;
// 		if(machine_code){
// 			next();
// 		}else{
// 			next({
// 				name: 'login'
// 			})
// 		}
// 	}else{
// 		next();
// 	}
// });
router.afterEach(to => {
    window.document.title = to.meta.title;
})

export default router
