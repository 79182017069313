import request from '@/libs/request'

/**
 * 获取设备数据
 * @returns 
 */
export function apiGetMachineInfo(params) {
    return request.get('/v2/machine_info', params)
}
/**
 * 上传文件
 * @returns 
 */
export function apiUploadFile(params) {
    return request.post('/upload_ai', params, {'Content-type':'multipart/form-data'})
}
/**
 * 获取图片
 * @returns 
 */
export function apiGetOrderImages(params) {
    return request.get('/order_image', params)
}
/**
 * 选择套餐
 * @returns 
 */
export function apiSelectPackage(params) {
    return request.post('/package', params)
}


/**
 * 生成生日宇宙数据
 * @returns
 */
export function apiBirthdayData(params) {
    return request.post('/v2/birthday_data', params)
}
/**
 * 生日宇宙订单
 * @returns
 */
export function apiGetBirthdayOrder(id) {
    return request.get(`/v2/birthday_order/${id}`)
}
/**
 * 生日宇宙选择套餐
 * @returns
 */
export function apiBirthdaySettle(params) {
    return request.post('/v2/birthday_settle', params)
}


/**
 * 支付结果查询
 * @returns
 */
export function apiPayStatus(params) {
    return request.post('/pay/query', params)
}