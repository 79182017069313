import axios from 'axios'
import Config from '@/config.js'

const service = axios.create({
    baseURL: Config.apiBaseURL,
    timeout: 30000 // 请求超时时间
})

function requestBase(options) {
    return new Promise((resolve, reject) => {
        service(options).then(result => {
            let res = result.data;
            if(res.status == 200){
                return resolve(res);
            }else{
                return reject(res);
            }
        }).catch(err => {
            if (!err.response) {
                err.message = '连接超时';
            } else {
                err.message = err.response.data.message || '未知错误';
            }
            return reject(err)
        });
    })
}

const request = ['post', 'put', 'delete'].reduce((request, method) => {
    request[method] = (url, data = {}, headers = {}) => {
        let options = {
            headers: headers
        }
        return requestBase(
            Object.assign({ url, data, method }, options)
        )
    }
    return request
}, {});
['get'].forEach(method => {
    request[method] = (url, params = {}, headers = {}) => {
        let options = {
            headers: headers
        }
        return requestBase(
            Object.assign({ url, params, method }, options)
        )
    }
})

export default request
