import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/libs/rem.js"


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import utils from '@/utils/utils';
Vue.prototype.$utils = utils;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
