//如果独立站点部署请配置新的URL
//整站接口请求地址
let API_URL = ''

if (process.env.NODE_ENV === 'development') { // 测试环境
    // API_URL = `http://172.16.2.214:7443/api`
    API_URL = `http://www.mx-shop.co/aiapi`
    // API_URL = `https://telescope.interestar.net/aiapi`
}else{ // 生产环境请求接口地址 如果没有配置自动获取当前网址路径，如果独立部署请自行配置请求URL
    // API_URL = API_URL || `${location.origin}/api`
    API_URL = `https://telescope.interestar.net/aiapi`
}

const Config = {
    siteName: 'TPEXT',
    // 接口请求地址
    apiBaseURL: API_URL,
    // 路由模式，可选值为 history 或 hash
    routerMode: 'history',
}

export default Config