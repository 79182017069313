<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { apiGetMachineInfo } from '@/api/app';
	export default {
		data(){
			return {}
		},
		computed: {
			...mapGetters(['aiMachineCode', 'birthdayMachineCode']),
		},
		created(){
            if(this.aiMachineCode){
                apiGetMachineInfo({code: this.aiMachineCode}).then(res => {
                    this.setAiMachineInfo(res.data);
                }).catch(err => {});
            }
            if(this.birthdayMachineCode){
                apiGetMachineInfo({code: this.birthdayMachineCode}).then(res => {
                    this.setBirthdayMachineInfo(res.data);
                }).catch(err => {});
            }
		},
        methods: {
            ...mapMutations(['setAiMachineInfo', 'setBirthdayMachineInfo']),
		}
	}
</script>

<style lang="scss">
    @font-face {
        font-family: 'bangBang';
        src: url('@/static/font/bangBang.ttf');
    }
    @font-face {
        font-family: 'lianMeng';
        src: url('@/static/font/lianmengqiyilushuaizhengruiheiti.ttf');
    }
    @font-face {
        font-family: 'XinYi';
        src: url('@/static/font/XinYiGuanHeiTi.ttf');
    }

    @keyframes move {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    /* 进入动画 */
    .wrap-enter-active {
        animation: move 1.5s;
    }
    /* 离开动画 */
    .wrap-leave-active {
        animation: move 1.5s reverse;
    }

	body, html {
		margin: 0;
		padding: 0;
		border: 0;
        font-size: 0.16rem;
		background-color: #000000;
	}
    .back_wrap {
        width: 100vw;
        height: 100vh;
        background-size: cover;
    }
</style>
