import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
        aiMachineInfo: '',
		aiMachineCode: localStorage.getItem('ai_machine_code') || null,
        aiFileStore: {},
        birthdayMachineInfo: '',
		birthdayMachineCode: localStorage.getItem('birthday_machine_code') || null,
        birthdayClient: localStorage.getItem('birthday_client')==1 ? true : false,
	},
	getters: {
		aiMachineInfo: state => state.aiMachineInfo ? JSON.parse(state.aiMachineInfo) : {},
		aiMachineCode: state => state.aiMachineCode,
		aiFileStore: state => state.aiFileStore,
		birthdayMachineInfo: state => state.birthdayMachineInfo ? JSON.parse(state.birthdayMachineInfo) : {},
		birthdayMachineCode: state => state.birthdayMachineCode,
		birthdayClient: state => state.birthdayClient,
	},
	mutations: {
		// 设置设备信息
		setAiMachineInfo(state, value) {
			state.aiMachineInfo = JSON.stringify(value) ?? '';
		},
		// 设置设备code
		setAiMachineCode(state, value) {
			state.aiMachineCode = value;
			localStorage.setItem('ai_machine_code', value);
		},
		setAiFileStore(state, value) {
			state.aiFileStore = value;
		},
		// 设置设备信息
		setBirthdayMachineInfo(state, value) {
			state.birthdayMachineInfo = JSON.stringify(value) ?? '';
		},
		// 设置设备code
		setBirthdayMachineCode(state, value) {
			state.birthdayMachineCode = value;
			localStorage.setItem('birthday_machine_code', value);
		},
		// 设置用户端信息
		setBirthdayClient(state, value) {
			state.birthdayClient = value;
			localStorage.setItem('birthday_client', value?1:0);
		},
	},
	actions: {
	},
	modules: {
	}
})
